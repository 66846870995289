import { addEventListener } from "@oddcamp/js-utils/src/event"
import { toggleClass } from "@oddcamp/js-utils/src/attribute"

import init from "../../shared/utils/init"

// activate/disable next button

init(() => {
  if (!document.querySelector(`.register-organization-page__footer`)) return

  const toggleNextButton = document.querySelector(
    `.register-organization-page__footer .js--toggle-submit`
  )

  const nextButton = `.register-organization-page__footer a.button`

  if (!toggleNextButton) return

  addEventListener(toggleNextButton, `change`, () => {
    toggleClass(nextButton, `button--disabled`)
  })
})
