import init from "../utils/init"

init(() => {
  const checkboxDropdowns = document.querySelectorAll(`.dropdown-checkboxes`)

  checkboxDropdowns.forEach((dropdown) => {
    const panel = dropdown.querySelector(`.dropdown-checkboxes__panel`)
    const button = dropdown.querySelector(`.dropdown-checkboxes__button`)
    const options = dropdown.querySelectorAll(`.dropdown-checkboxes__option`)
    const currentValue = button.querySelector(`.dropdown-checkboxes__value`)

    function handleClick(e) {
      const target = e.target

      if (!button.contains(target) && !panel.contains(target)) {
        closeDropdown()
      }
    }

    function handleKeyup(e) {
      const key = e.key

      if (key === `Escape`) {
        closeDropdown()
      }
    }

    function expandDropdown() {
      // expand
      panel.classList.add(`dropdown-checkboxes__panel--open`)
      button.ariaExpanded = `true`
    }

    function closeDropdown() {
      // close
      panel.classList.remove(`dropdown-checkboxes__panel--open`)
      button.ariaExpanded = `false`
    }

    document.addEventListener(`click`, handleClick)
    document.addEventListener(`keyup`, handleKeyup)

    button.addEventListener(`click`, () => {
      const isExpanded = button.ariaExpanded === `true`

      if (!isExpanded) {
        expandDropdown()
      } else {
        closeDropdown()
      }
    })

    options.forEach((option) => {
      option.addEventListener(`change`, () => {
        updateSelected({ options, currentValue })
      })
    })

    updateSelected({ options, currentValue })
  })
})

function updateSelected({ options, currentValue }) {
  const selectedItems = []
  options.forEach((option) => {
    const parent = option.parentNode
    const textContent = parent.textContent
    const checked = option.checked

    if (checked) {
      selectedItems.push(textContent)
    }
  })

  currentValue.textContent = selectedItems.join(`, `)
}
