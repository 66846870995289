import { addEventListener } from "@oddcamp/js-utils/src/event"
import { getElements } from "@oddcamp/js-utils/src/selector"

import init from "../../shared/utils/init"

// reset group inputs

init(() => {
  const resetBtns = getElements(`.form-group summary button`)
  if (!resetBtns) return

  addEventListener(resetBtns, `click`, (e, triggerEl) => {
    triggerEl
      .closest(`summary`)
      .nextElementSibling.querySelectorAll(
        `input, select, textarea, .flatpickr-input`
      )
      .forEach((el) => {
        switch (el.type) {
          case `checkbox`:
          case `radio`:
            el.checked = false
            break
          case `range`:
            el.value = 0
            break
          default:
            el.value = ``
        }

        // Choices.js powered select

        if (el.Choices) {
          el.Choices.clearStore()
        }

        // Date picker

        if (el._flatpickr) {
          el._flatpickr.clear()
        }
      })

    triggerEl.closest(`form`).submit()
  })
})
