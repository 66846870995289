import { delegate } from "tippy.js"
import { createFocusTrap } from "focus-trap"
import { delegateEventListener } from "@oddcamp/js-utils/src/event"

import init from "../../shared/utils/init"

let focusTarpInstance = null

delegateEventListener(`[data-tooltip="click"]`, `click`, (e) =>
  e.preventDefault()
)

init(() => {
  const config = {
    appendTo: document.body,
    allowHTML: true,
    interactive: true,
    aria: false,
    ignoreAttributes: true,
    theme: `tooltip`,
    animation: `shift-away`,
    placement: `bottom-start`,

    onShow(instance) {
      const {
        tooltipContent,
        tooltipText,
        tooltipTheme,
        tooltipClassname,
        tooltipProps,
      } = instance.reference.dataset

      const tippyBoxEl = instance.popper.querySelector(`.tippy-box`)

      let content = ``
      if (tooltipContent) {
        if (tooltipContent.includes(`^`)) {
          const selectors = tooltipContent.split(`^`)
          content = instance.reference
            .closest(selectors[0].trim())
            .querySelector(selectors[1].trim()).innerHTML
        } else {
          content = document.querySelector(tooltipContent).innerHTML
        }
      } else {
        content = tooltipText
        tippyBoxEl.classList.add(`tippy-box--text`)
      }
      instance.setContent(content)

      if (tooltipTheme) {
        tippyBoxEl.classList.add(`tippy-box--theme-${tooltipTheme}`)
      }

      if (tooltipClassname) {
        tippyBoxEl.classList.add(tooltipClassname)
      }

      if (tooltipProps) {
        instance.setProps(JSON.parse(tooltipProps))
      }

      // @TODO: aria
      // @TODO: doesn't work in pair with ModalComponent
      try {
        focusTarpInstance = createFocusTrap([instance.popper], {
          onDeactivate() {
            instance.hide()
          },
        })
        focusTarpInstance.activate()
      } catch (error) {
        //
      }
    },

    onHide() {
      if (focusTarpInstance) {
        focusTarpInstance.deactivate()
        focusTarpInstance = null
      }
    },
  }

  // click

  delegate(document.body, {
    ...config,
    target: `[data-tooltip="click"]`,
    trigger: `click`,
  })

  // hover

  delegate(document.body, {
    ...config,
    target: `[data-tooltip="hover"]`,
    interactiveBorder: 20,
  })
})
