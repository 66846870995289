import init from "../../shared/utils/init"

init(() => {
  const showMoreFiltersButton = document.querySelector(`.showMoreFilters`)
  if (!showMoreFiltersButton) return

  showMoreFiltersButton.addEventListener(`click`, () => {
    const form = document.querySelector(`.mission-search-page-form`)
    form.classList.add(`mission-search-page-form--showAll`)
  })
})
