import { addClass } from "@oddcamp/js-utils/src/attribute"
import { getElements } from "@oddcamp/js-utils/src/selector"
import {
  delegateEventListener,
  addEventListener,
} from "@oddcamp/js-utils/src/event"

import init from "../utils/init"

let activeTrigger = null
let activeTarget = null

// splice the array
const getUnique = (array) => {
  const newArr = array
  let h
  let i
  let j

  for (h = 0; h < array.length; h++) {
    const curItem = array[h]
    let foundCount = 0

    for (i = 0; i < array.length; i++) {
      if (array[i] == array[h]) foundCount++
    }
    if (foundCount > 1) {
      for (j = 0; j < newArr.length; j++) {
        if (newArr[j] == curItem) {
          newArr.splice(j, 1)
          j--
        }
      }
    }
  }

  return newArr
}

const listCheckedLocations = (locationCheckboxes) => {
  const locationSearchData = []

  locationCheckboxes.forEach((checkbox) => {
    if (checkbox.checked) locationSearchData.push(checkbox.nextSibling.data)
  })

  if (getUnique(locationSearchData).length > 1) {
    document.querySelector(`.js--optgroup-content-location`).innerHTML =
      getUnique(locationSearchData)[0] +
      ` (+ ` +
      (getUnique(locationSearchData).length - 1) +
      ` till)`
  } else if (
    getUnique(locationSearchData) === undefined ||
    getUnique(locationSearchData).length == 0
  ) {
    document.querySelector(`.js--optgroup-content-location`).innerHTML =
      document.querySelector(`.js--optgroup-content-location`).dataset.text
  } else {
    document.querySelector(`.js--optgroup-content-location`).innerHTML =
      getUnique(locationSearchData)
  }
}

const listCheckedCauses = (causeCheckboxes) => {
  const causeSearchData = []

  causeCheckboxes.forEach((checkbox) => {
    if (checkbox.checked) causeSearchData.push(checkbox.nextSibling.data)
  })

  if (getUnique(causeSearchData).length > 1) {
    document.querySelector(`.js--optgroup-content-cause`).innerHTML =
      getUnique(causeSearchData)[0] +
      ` (+ ` +
      (getUnique(causeSearchData).length - 1) +
      ` till)`
  } else if (
    getUnique(causeSearchData) === undefined ||
    getUnique(causeSearchData).length == 0
  ) {
    document.querySelector(
      `.js--optgroup-content-cause`
    ).innerHTML = `Alla kategorier`
  } else {
    document.querySelector(`.js--optgroup-content-cause`).innerHTML =
      getUnique(causeSearchData)
  }
}

const populateSearch = () => {
  const locationCheckboxes = getElements(`.js--checkbox-plats`)
  listCheckedLocations(locationCheckboxes)
  const causeCheckboxes = getElements(`.js--checkbox-ändamål`)
  listCheckedCauses(causeCheckboxes)

  const hasCheckbox = (el) => el.type === `checkbox`

  causeCheckboxes.forEach((causeCheckbox) => {
    addEventListener(causeCheckbox, `change`, () => {
      listCheckedCauses(causeCheckboxes)
    })
  })

  if (locationCheckboxes.some(hasCheckbox)) {
    locationCheckboxes.forEach((locationCheckbox) => {
      addEventListener(locationCheckbox, `change`, () => {
        listCheckedLocations(locationCheckboxes)
      })
    })
  } else {
    locationCheckboxes.forEach((locationCheckbox) => {
      addEventListener(locationCheckbox, `change`, () => {
        const locationSearchData = locationCheckbox.nextSibling.data

        if (locationCheckbox.checked) {
          document.querySelector(`.js--optgroup-content-location`).innerHTML =
            locationSearchData
        } else {
          document.querySelector(
            `.js--optgroup-content-location`
          ).innerHTML = `Välj`
        }
      })
    })
  }
}

const doShow = (trigger) => {
  const target = trigger.nextElementSibling

  addClass(target, `--active`)
  addClass(trigger, `--active`)

  activeTrigger = trigger
  activeTarget = target
}

const doHide = () => {
  activeTrigger.classList.remove(`--active`)
  activeTarget.classList.remove(`--active`)

  activeTrigger = null
  activeTarget = null
}

init(() => {
  if (!document.querySelector(`.-search-form`)) return
  const tiggersClose = getElements(`.js--filter-close`)

  tiggersClose.forEach((tiggerClose) => {
    addEventListener(tiggerClose, `click`, (e) => {
      e.preventDefault()
      doHide()
    })
  })

  document.addEventListener(`click`, (e) => {
    const trigger = e.target.closest(`.js--optgroup-trigger`)

    if (trigger) {
      e.preventDefault()
      const show = trigger !== activeTrigger
      if (activeTrigger) doHide()
      if (show) doShow(trigger)
    } else if (activeTrigger) {
      if (!e.target.closest(`.search-box-optgroup`)) doHide()
    }
  })

  populateSearch()
})

// select/clear options

const getSiblings = (elem) => {
  return Array.prototype.filter.call(
    elem.parentNode.children,
    function (sibling) {
      return sibling !== elem
    }
  )
}

delegateEventListener(`.js--checkbox-toggle`, `click`, (e, trigger) => {
  const event = new Event(`change`)
  const thisCheckboxes = getSiblings(trigger.parentNode)[0].querySelectorAll(
    `input`
  )

  if (e.target.value == `Välj alla`) {
    thisCheckboxes.forEach((checkbox) => {
      if (!checkbox.checked) {
        checkbox.checked = true
        checkbox.dispatchEvent(event)
      }
    })
  } else {
    thisCheckboxes.forEach((checkbox) => {
      if (checkbox.checked) {
        checkbox.checked = false
        checkbox.dispatchEvent(event)
      }
    })
  }
})
