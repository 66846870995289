import Swiper, { Navigation } from "swiper"
import { getElements } from "@oddcamp/js-utils/src/selector"
import { delegateEventListener } from "@oddcamp/js-utils/src/event"

import init from "../utils/init"

// swiper

Swiper.use([Navigation])

init(() => {
  getElements(`.carousel-modal-overlay`).forEach((overlayEl) => {
    const modalEl = overlayEl.closest(`.modal`)
    const indexEl = modalEl.querySelector(`.carousel-modal-status b`)

    modalEl.addEventListener(`modalInited`, (e) => {
      const initialSlide =
        (e.detail.triggerEl && e.detail.triggerEl.dataset.modalShowIndex) || 0

      modalEl.Modal.on(`show`, () => {
        new Swiper(overlayEl.querySelector(`.swiper-container`), {
          initialSlide,
          slidesPerView: 1,
          navigation: {
            nextEl: overlayEl.querySelector(`.swiper-button-next`),
            prevEl: overlayEl.querySelector(`.swiper-button-prev`),
          },
          on: {
            slideChange: (e) => {
              indexEl.innerHTML = e.activeIndex + 1
            },
          },
        })
      })
    })
  })
})

// close the modal on outside click

delegateEventListener(`.carousel-modal-overlay`, `click`, (e) => {
  const whitelist = [
    `.carousel-modal-header h2`,
    `.carousel-modal-header button`,
    `.carousel-modal-slide-content img`,
    `.carousel-modal-status > div`,
    `.carousel-modal-prev`,
    `.carousel-modal-next`,
  ]

  if (!e.target.closest(whitelist.join(`,`))) {
    const modalEl = e.target.closest(`.modal`)

    if (modalEl) {
      modalEl.Modal.hide()
    }
  }
})
