import { addEventListener } from "@oddcamp/js-utils/src/event"
import { getElements } from "@oddcamp/js-utils/src/selector"
import { addClass, removeClass } from "@oddcamp/js-utils/src/attribute"
import { createFocusTrap } from "focus-trap"

import init from "../../shared/utils/init"

// reset group inputs

init(() => {
  if (!document.querySelector(`.organizations-page-form__group`)) return

  addEventListener(
    `.organizations-page-form__group summary button`,
    `click`,
    (e, triggerEl) => {
      triggerEl
        .closest(`summary`)
        .nextElementSibling.querySelectorAll(`input, select, textarea`)
        .forEach((el) => {
          switch (el.type) {
            case `checkbox`:
            case `radio`:
              el.checked = false
              break
            default:
              el.value = ``
          }

          if (el.Choices) {
            el.Choices.clearStore()
          }
        })

      triggerEl.closest(`.organizations-page-form`).submit()
    }
  )
})

// show/hide list of checkboxes

init(() => {
  const displayNum = 5
  const lists = getElements(`.organizations-page-form__group .\\--checkboxes`)
  if (!lists.length) return

  lists.forEach((list) => {
    const checkboxes = getElements(`.form-checkbox`, list)
    addClass(checkboxes.slice(displayNum), `is-hidden`)
    if (checkboxes.length > displayNum) {
      const toggle = list
        .closest(`.organizations-page-form__group`)
        .querySelector(`.organizations-page-form__toggle`)
      if (toggle) toggle.classList.remove(`is-hidden`)
    }
  })

  addEventListener(
    document.querySelectorAll(`.organizations-page-form__toggle`),
    `click`,
    (e, triggerEl) => {
      e.preventDefault()

      const checkboxes = getElements(
        `.form-checkbox`,
        triggerEl.closest(`.organizations-page-form__group`)
      )

      triggerEl.classList.toggle(`--active`)

      if (triggerEl.classList.contains(`--active`)) {
        removeClass(checkboxes, `is-hidden`)
      } else {
        addClass(checkboxes.slice(displayNum), `is-hidden`)
      }
    }
  )
})

// mobile form toggling

init(() => {
  const form = document.querySelector(`.organizations-page-form`)
  const toggleEl = document.querySelector(`.organizations-page-form-toggle`)
  if (!form || !toggleEl) return

  const focusTrap = createFocusTrap(form)

  addEventListener(`.js--organizations-page-form-toggle`, `click`, () => {
    form.classList.toggle(`--active`)
    const isActive = form.classList.contains(`--active`)

    document.documentElement.classList.toggle(
      `--disable-scroll-mobile`,
      isActive
    )

    if (isActive) {
      focusTrap.activate()
    } else {
      focusTrap.deactivate()
      toggleEl.focus()
    }
  })
})
