import Swiper, { Navigation } from "swiper"
import { getElements } from "@oddcamp/js-utils/src/selector"

import init from "../../shared/utils/init"

// swiper

Swiper.use([Navigation])

init(() => {
  getElements(`.cards-carousel`).forEach((containerEl) => {
    new Swiper(containerEl.querySelector(`.swiper-container`), {
      slidesPerView: `auto`,
      navigation: {
        nextEl: containerEl.querySelector(`.swiper-button-next`),
        prevEl: containerEl.querySelector(`.swiper-button-prev`),
      },
    })
  })
})
