import * as Sentry from "@sentry/browser"
import { Integrations } from "@sentry/tracing"

Sentry.init({
  dns: window.sentry_dsn,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: window.sentry_js_sample_rate,
})
