import { Swiper, Pagination, EffectFade } from "swiper"
import { getElements } from "@oddcamp/js-utils/src/selector"

import init from "../../shared/utils/init"

// swiper

Swiper.use([Pagination, EffectFade])

init(() => {
  getElements(`.story-carousel`).forEach((containerEl) => {
    new Swiper(containerEl.querySelector(`.swiper-container`), {
      slidesPerView: 1,
      effect: `fade`,
      fadeEffect: {
        crossFade: true,
      },
      pagination: {
        el: `.swiper-pagination`,
        type: `bullets`,
        clickable: true,
      },
    })
  })
})
