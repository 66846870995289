import { Map, Marker, NavigationControl, FullscreenControl } from "mapbox-gl"

import init from "../../shared/utils/init"

init(() => {
  const els = document.querySelectorAll(`.contact-page-location__map`)
  if (!els.length) return

  els.forEach((el) => {
    const coordinates = el.dataset.coordinates.split(`,`)

    const map = new Map({
      container: el,
      accessToken: `pk.eyJ1Ijoib2RkY2FtcCIsImEiOiJja2w5NzF0cWgwbXdxMnBydjA1angwZWt1In0.46134emdokQYXY2WwM2Pvw`,
      style: `mapbox://styles/oddcamp/ckl97jksi0o4f17nrdi7mbnnf`,
      center: coordinates,
      zoom: 14,
    })

    new Marker({ color: `#d0345a` }).setLngLat(coordinates).addTo(map)

    map.addControl(new NavigationControl())
    map.addControl(new FullscreenControl())
  })
})
