import { addClass, removeClass } from "@oddcamp/js-utils/src/attribute"

import init from "../../shared/utils/init"

init(() => {
  const formEl = document.getElementById(`education-application-page-form`)
  if (!formEl) return

  const submitEl = formEl.querySelector(`button[type="submit"]`)

  const nextButtonEl = formEl.querySelector(
    `.js--education-application-page-next`
  )

  const prevButtonEl = formEl.querySelector(
    `.js--education-application-page-prev`
  )

  const fieldsInputEls = formEl
    .querySelector(`.education-application-page-screen[data-screen="fields"]`)
    .querySelectorAll(`input, select, textarea`)

  const onlyConfirmation = formEl.querySelector(
    `#event_application_only_confirmation_page`
  )

  const activateScreen = (name) => {
    removeClass(`.education-application-page-screen`, `--active`)
    addClass(
      `.education-application-page-screen[data-screen="${name}"]`,
      `--active`
    )
    removeClass(`.education-application-page-progress li`, `--active`)
    addClass(
      `.education-application-page-progress li[data-screen="${name}"]`,
      `--active`
    )
  }

  const populateConfirmationData = () => {
    formEl
      .querySelectorAll(`.education-application-page-data [data-input]`)
      .forEach((el) => {
        const inputEl = formEl.querySelector(
          `[name*="${el.dataset.input}"]:not([type="hidden"])`
        )

        if (inputEl && inputEl.value.trim().length) {
          let { value } = inputEl

          switch (inputEl.tagName.toLowerCase()) {
            case `select`:
              value = inputEl.options[inputEl.selectedIndex].text
              break
            case `input`:
              if (inputEl.type == `checkbox`) {
                value = inputEl.checked
                  ? formEl.dataset.textYes
                  : formEl.dataset.textNo
              }
              break
          }

          el.innerHTML = value
        }
      })
  }

  nextButtonEl.addEventListener(`click`, () => {
    const allValid = ![...fieldsInputEls].find((el) => !el.validity.valid)
    if (allValid) {
      populateConfirmationData()
      activateScreen(`confirmation`)
    } else {
      submitEl.click() // displays browser native validation errors
    }
  })

  if (prevButtonEl) {
    prevButtonEl.addEventListener(`click`, () => activateScreen(`fields`))
  }

  if (onlyConfirmation.value === `true`) {
    populateConfirmationData()
    activateScreen(`confirmation`)
  }
})
