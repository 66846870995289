import { addEventListener } from "@oddcamp/js-utils/src/event"
import { getElements } from "@oddcamp/js-utils/src/selector"

import init from "../../shared/utils/init"

init(() => {
  const toggleEls = getElements(`.form-image-checkbox input`)
  if (!toggleEls.length) return

  addEventListener(toggleEls, `change`, (e, triggerEl) => {
    triggerEl
      .closest(`.form-image-checkbox`)
      .classList.toggle(`--active`, triggerEl.checked)
  })
})

init(() => {
  const telInputEl = document.getElementById(`user_phone_number`)
  const telInputRequiredEl = document.querySelector(
    `label[for="user_phone_number"] span`
  )
  const typeVolunteerEl = document.getElementById(`user_user_type_volunteer`)
  const typeOrganizationEl = document.getElementById(
    `user_user_type_organization`
  )

  if (
    !telInputEl ||
    !telInputRequiredEl ||
    !typeVolunteerEl ||
    !typeOrganizationEl
  )
    return

  const setTelRequired = () => {
    const required = typeVolunteerEl.checked
    telInputEl.required = required
    telInputEl.ariaRequired = required
    telInputRequiredEl.classList.toggle(`is-hidden`, !required)
  }

  addEventListener(
    [typeVolunteerEl, typeOrganizationEl],
    `change`,
    setTelRequired
  )

  setTelRequired()
})
