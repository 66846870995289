import {
  addEventListener,
  delegateEventListener,
} from "@oddcamp/js-utils/src/event"
import { createFocusTrap } from "focus-trap"
import Cookies from "js-cookie"

import init from "../../shared/utils/init"

init(() => {
  const container = document.querySelector(`.v-cookie-consent-overlay`)

  // Content blockers on iOS sometimes force display: none on the cookie banner
  // so we not only check that the container exists, but that it's visible too
  if (!container || window.getComputedStyle(container).display === `none`)
    return

  const focusTrap = createFocusTrap(container)
  focusTrap.activate()
  document.querySelector(`.v-cookie-consent__cta button`).focus()
  document.documentElement.classList.add(`--disable-scroll`)

  addEventListener(`.v-cookie-consent__cta button`, `click`, (e, target) => {
    const { value } = target.dataset
    Cookies.set(container.dataset.cookieName, value, { expires: 365 })
    focusTrap.deactivate()
    document.documentElement.classList.remove(`--disable-scroll`)
    container.remove()

    if (value == `all`) {
      const script = document.createElement(`script`)
      script.type = `text/javascript`
      script.text = document.querySelector(
        `.js--cookie-consent-script`
      ).innerHTML
      document.body.appendChild(script)
    }
  })
})

// resetter

delegateEventListener(`.js--cookie-consent-reset`, `click`, (e, triggerEl) => {
  Cookies.remove(triggerEl.dataset.cookieName)
  window.scrollTo(0, 0)
  window.location.reload()
})
