import { addEventListener } from "@oddcamp/js-utils/src/event"
import { addClass, toggleClass } from "@oddcamp/js-utils/src/attribute"
import { createFocusTrap } from "focus-trap"
import flatpickr from "flatpickr"
import { Swedish } from "flatpickr/dist/l10n/sv"
import { format } from "date-fns"

import init from "../../shared/utils/init"

// bookmarking

init(() => {
  const buttons = document.querySelectorAll(`.js--activity-page-bookmark`)
  if (!buttons) return

  const disableButtons = () => buttons.forEach((b) => (b.disabled = true))
  const enableButtons = () => buttons.forEach((b) => (b.disabled = false))

  const displayOnlyButtons = (method) => {
    addClass(buttons, `is-hidden`)
    buttons.forEach(
      (b) => b.dataset.method == method && b.classList.remove(`is-hidden`)
    )
  }

  addEventListener(buttons, `ajax:before`, (e, buttonEl) => {
    disableButtons()
    displayOnlyButtons(buttonEl.dataset.method == `post` ? `delete` : `post`)
  })

  addEventListener(buttons, `ajax:complete`, enableButtons)
  addEventListener(buttons, `ajax:error`, (e, buttonEl) =>
    toggleClass(buttons, `is-hidden`)
  )
})

// mobile form toggling

init(() => {
  const form = document.querySelector(`.activities-page-form`)
  const toggleEl = document.querySelector(`.activities-page-form-toggle`)
  if (!form || !toggleEl) return

  const focusTrap = createFocusTrap(form)

  addEventListener(`.js--activities-page-form-toggle`, `click`, () => {
    form.classList.toggle(`--active`)
    const isActive = form.classList.contains(`--active`)

    document.documentElement.classList.toggle(
      `--disable-scroll-mobile`,
      isActive
    )

    if (isActive) {
      focusTrap.activate()
    } else {
      focusTrap.deactivate()
      toggleEl.focus()
    }
  })
})

// form date picker

init(() => {
  const picker = document.querySelector(`.activities-page-form__date__picker`)
  const inputs = document.querySelector(`.activities-page-form__date__inputs`)
  const inputStart = document.querySelector(
    `.js--activities-page-form-date-start`
  )
  const inputEnd = document.querySelector(`.js--activities-page-form-date-end`)

  if (!picker || !inputs || !inputStart || !inputEnd) return

  const actDates = JSON.parse(picker.dataset.dateCounts)

  inputs.classList.add(`is-vhidden`)

  const defaultDate =
    inputStart.value && inputEnd.value ? [inputStart.value, inputEnd.value] : []

  flatpickr(picker, {
    defaultDate,
    inline: true,
    mode: `range`,
    locale: Swedish,
    onChange: (selectedDates) => {
      const dates = selectedDates.map((d) => format(d, `yyyy-MM-dd`))
      inputStart.value = dates[0]
      inputEnd.value = dates[1]
    },
    onDayCreate: function (dObj, dStr, fp, dayElem) {
      if (actDates.includes(format(dayElem.dateObj, `yyyy-MM-dd`))) {
        dayElem.innerHTML += `<span class='pin'></span>`
      }
    },
  })
})
