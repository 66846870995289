import axios from "axios"

import { addEventListener } from "@oddcamp/js-utils/src/event"
import { toggleClass } from "@oddcamp/js-utils/src/attribute"
import { createFocusTrap } from "focus-trap"

import init from "../../shared/utils/init"

// mobile form toggling

init(() => {
  const form = document.querySelector(`.mission-search-page-form`)
  const toggleEl = document.querySelector(`.mission-search-page-form-toggle`)
  if (!form || !toggleEl) return

  const focusTrap = createFocusTrap(form)

  addEventListener(`.js--mission-search-page-form-toggle`, `click`, () => {
    form.classList.toggle(`--active`)
    const isActive = form.classList.contains(`--active`)

    document.documentElement.classList.toggle(
      `--disable-scroll-mobile`,
      isActive
    )

    if (isActive) {
      focusTrap.activate()
    } else {
      focusTrap.deactivate()
      toggleEl.focus()
    }
  })
})

// form time range

init(() => {
  const labelEl = document.querySelector(
    `.mission-search-page-form-time-range-label`
  )
  if (!labelEl) return

  const inputEl = labelEl.nextElementSibling
  const valueEl = labelEl.querySelector(`strong`)

  inputEl.addEventListener(`change`, () => {
    valueEl.innerHTML = inputEl.value
  })
})

// counties, communes, areas

init(() => {
  const countyEl = document.querySelector(`.js--mission-search-page-county`)
  const locationEl = document.querySelector(`.js--mission-search-page-location`)
  if (!countyEl || !locationEl) return

  const csrfToken = document.querySelector(`meta[name=csrf-token]`).content

  axios.defaults.headers.common[`X-CSRF-Token`] = csrfToken

  const fetchLocations = (countyId, preselectedValues = null) => {
    axios({
      method: `get`,
      url: `/api/v1/locations.json?county_id=${encodeURIComponent(countyId)}`,
    }).then(function (response) {
      // Another clearStore if intercept didn't fire it
      locationEl.Choices.clearStore()

      locationEl
        .closest(`.form-row`)
        .classList.toggle(`is-hidden`, !response.data.length)

      locationEl.Choices.setChoices(response.data, `id`, `name`, true)

      if (preselectedValues && response.data.length)
        locationEl.Choices.setChoiceByValue(preselectedValues)
    })

    // Intercept request
    axios.interceptors.request.use(function (config) {
      locationEl.Choices.clearStore()

      return config
    })
  }

  countyEl.addEventListener(`change`, (e) => fetchLocations(e.detail.value))

  if (countyEl.value) {
    fetchLocations(
      countyEl.value,
      [...locationEl.options].map((o) => o.value)
    )
  }
})

// trigger Follow component's modal

init(() => {
  const triggerEl = document.querySelector(
    `.js--mission-search-page-trigger-follow-modal`
  )
  const followEl = document.querySelector(`.js--mission-search-page-follow`)
  if (!triggerEl || !followEl) return

  triggerEl.addEventListener(`click`, () => {
    followEl.dispatchEvent(new CustomEvent(`follow:displayModal`))
  })
})

// bookmarks (heart-icon)

init(() => {
  const buttons = document.querySelectorAll(`.js--mission-search-page-bookmark`)

  if (!buttons) return

  const disableButtons = (btns) => btns.forEach((b) => (b.disabled = true))
  const enableButtons = () => buttons.forEach((b) => (b.disabled = false))

  const displayOnlyButtons = (buttonEl, method) => {
    buttonEl.parentNode
      .querySelectorAll(`.js--mission-search-page-bookmark`)
      .forEach((b) => {
        if (b.dataset.method === method) {
          b.classList.remove(`is-hidden`)
        } else {
          b.classList.add(`is-hidden`)
        }
      })
  }

  addEventListener(buttons, `ajax:before`, (e, buttonEl) => {
    disableButtons(
      buttonEl.parentNode.querySelectorAll(`.js--mission-search-page-bookmark`)
    )
    displayOnlyButtons(
      buttonEl,
      buttonEl.dataset.method === `post` ? `delete` : `post`
    )
  })

  addEventListener(buttons, `ajax:complete`, enableButtons)
  addEventListener(buttons, `ajax:error`, (e, buttonEl) =>
    toggleClass(buttonEl, `is-hidden`)
  )
})
