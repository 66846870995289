import { addEventListener } from "@oddcamp/js-utils/src/event"

import init from "../../shared/utils/init"

init(() => {
  addEventListener(`.pickers input`, `change`, (e) => {
    const html = document.documentElement
    const body = document.body
    html.classList.add(`--theme-portal`)
    body.style.setProperty(
      `--${e.currentTarget.id}Color`,
      e.currentTarget.value
    )
  })
})
