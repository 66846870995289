import { Swiper, Pagination, EffectFade } from "swiper"
import { getElements } from "@oddcamp/js-utils/src/selector"

import init from "../../shared/utils/init"

// swiper

Swiper.use([Pagination, EffectFade])

init(() => {
  getElements(`.tags-carousel__inner`).forEach((containerEl) => {
    new Swiper(containerEl.querySelector(`.swiper-container`), {
      slidesPerView: 1,
      effect: `fade`,
      fadeEffect: {
        crossFade: true,
      },
      pagination: {
        el: `.swiper-pagination`,
        type: `bullets`,
        clickable: true,
      },
    })
  })

  const container = document.querySelector(
    `.tags-carousel__inner .swiper-container`
  )
  const nextButton = document.querySelector(`.tags-carousel__button--right`)
  const prevButton = document.querySelector(`.tags-carousel__button--left`)

  if (container && container.swiper) {
    const swiper = container.swiper

    /** Make hidden slides inert, so that they do not receive keyboard focus
     *  or are recognized by assistive technology. */
    const toggleInactiveSlides = () => {
      const slides = container.querySelectorAll(`.swiper-slide`)
      slides.forEach((slide) => {
        const isActive = slide.classList.contains(`swiper-slide-active`)
        if (isActive) {
          slide.removeAttribute(`inert`)
        } else {
          slide.setAttribute(`inert`, ``)
        }
      })
    }

    toggleInactiveSlides()
    swiper.on(`slideChange`, () => {
      setTimeout(() => {
        /*
      This timeout is here because the `slideChange` event, and seemingly every other event that swiper fires,
      is fired before the DOM updates. This means the `toggleInactiveSlides` function would not check the updated DOM,
      thus not updating the DOM at all.
      */
        toggleInactiveSlides()
      }, 50)
    })

    prevButton.addEventListener(`click`, () => {
      swiper.slidePrev(500)
    })

    nextButton.addEventListener(`click`, () => {
      swiper.slideNext(500)
    })
  }
})
