import { addEventListener } from "@oddcamp/js-utils/src/event"
import {
  addClass,
  removeClass,
  toggleClass,
} from "@oddcamp/js-utils/src/attribute"

import init from "../../shared/utils/init"

// reveal

init(() => {
  const lists = document.querySelectorAll(`.list-reveal .form-row`)

  if (!lists) return

  lists.forEach((list) => {
    const listEls = list.querySelectorAll(`.form-checkbox`)

    if (listEls.length > 5) {
      const overFive = list.querySelectorAll(`.form-checkbox:nth-of-type(n+6)`)
      let isHidden = true

      // reveal button
      const seeAllButton = document.createElement(`button`)
      seeAllButton.classList.add(`list-reveal__button`)
      seeAllButton.textContent = `Visa ${overFive.length} till`
      seeAllButton.setAttribute(`type`, `button`)

      addClass(overFive, `is-hidden`)

      list.append(seeAllButton)

      addEventListener(seeAllButton, `click`, (e) => {
        e.preventDefault()

        toggleClass(seeAllButton, `--open`, isHidden)

        if (isHidden) {
          removeClass(listEls, `is-hidden`)
          seeAllButton.textContent = `Visa mindre`
        } else {
          addClass(overFive, `is-hidden`)
          seeAllButton.textContent = `Visa ${overFive.length} till`
        }

        isHidden = !isHidden
      })
    }
  })
})
