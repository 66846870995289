document.addEventListener(`DOMContentLoaded`, function () {
  const links = document.querySelectorAll(`.trix-content a`)

  links.forEach(function (link) {
    if (
      link.href.includes(`.pdf`) ||
      link.href.includes(`/rails/active_storage`)
    ) {
      link.target = `_blank`
    }
  })
})
