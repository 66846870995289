import init from "../../shared/utils/init"

// remove message on anywhere click on "anywhere"

init(() => {
  const el = document.querySelector(`.flash-message`)
  if (!el) return

  document.addEventListener(`click`, () => el.remove())
})
