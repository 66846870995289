import { addClass, removeClass } from "@oddcamp/js-utils/src/attribute"

import init from "../../shared/utils/init"

init(() => {
  const formEl = document.getElementById(`mission-application-page-form`)
  if (!formEl) return

  const submitEl = formEl.querySelector(`button[type="submit"]`)
  const nextButtonEl = formEl.querySelector(
    `.js--mission-application-page-next`
  )
  const prevButtonEl = formEl.querySelector(
    `.js--mission-application-page-prev`
  )
  const fieldsInputEls = formEl
    .querySelector(`.mission-application-page-screen[data-screen="fields"]`)
    .querySelectorAll(`input, select, textarea`)

  const activateScreen = (name) => {
    removeClass(`.mission-application-page-screen`, `--active`)
    addClass(
      `.mission-application-page-screen[data-screen="${name}"]`,
      `--active`
    )
    removeClass(`.mission-application-page-progress li`, `--active`)
    addClass(
      `.mission-application-page-progress li[data-screen="${name}"]`,
      `--active`
    )
  }

  const populateConfirmationData = () => {
    formEl
      .querySelectorAll(`.mission-application-page-data [data-input]`)
      .forEach((el) => {
        const inputEl = formEl.querySelector(
          `[name*="${el.dataset.input}"]:not([type="hidden"])`
        )

        if (inputEl && inputEl.value.trim().length) {
          let { value } = inputEl

          switch (inputEl.tagName.toLowerCase()) {
            case `select`:
              if (el.dataset.inputType == `value`) {
                value = inputEl.options[inputEl.selectedIndex].value
              } else {
                value = inputEl.options[inputEl.selectedIndex].text
              }
              break
            case `input`:
              if (inputEl.type == `checkbox`) {
                value = inputEl.checked
                  ? formEl.dataset.textYes
                  : formEl.dataset.textNo
              }
              break
          }

          if (el.dataset.inputPad) {
            el.innerHTML = value.padStart(el.dataset.inputPad, `0`)
          } else {
            el.innerHTML = value
          }
        }
      })
  }

  nextButtonEl.addEventListener(`click`, () => {
    const allValid = ![...fieldsInputEls].find((el) => !el.validity.valid)
    if (allValid) {
      populateConfirmationData()
      activateScreen(`confirmation`)
    } else {
      submitEl.click() // displays browser native validation errors
    }
  })

  prevButtonEl.addEventListener(`click`, () => activateScreen(`fields`))
})
