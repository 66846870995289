import { addEventListener } from "@oddcamp/js-utils/src/event"
import { addClass, toggleClass } from "@oddcamp/js-utils/src/attribute"
import throttle from "raf-throttle"

import init from "../../shared/utils/init"

// bookmarking

init(() => {
  const buttons = document.querySelectorAll(`.js--mission-page-bookmark`)
  if (!buttons) return

  const disableButtons = () => buttons.forEach((b) => (b.disabled = true))
  const enableButtons = () => buttons.forEach((b) => (b.disabled = false))

  const displayOnlyButtons = (method) => {
    addClass(buttons, `is-hidden`)
    buttons.forEach(
      (b) => b.dataset.method == method && b.classList.remove(`is-hidden`)
    )
  }

  addEventListener(buttons, `ajax:before`, (e, buttonEl) => {
    disableButtons()
    displayOnlyButtons(buttonEl.dataset.method == `post` ? `delete` : `post`)
  })

  addEventListener(buttons, `ajax:complete`, enableButtons)
  addEventListener(buttons, `ajax:error`, (e, buttonEl) =>
    toggleClass(buttons, `is-hidden`)
  )
})

// sticky

init(() => {
  const el = document.querySelector(`.mission-page-sticky`)
  const refEl = document.querySelector(`.mission-page-container__main`)
  if (!el || !refEl) return

  window.addEventListener(
    `scroll`,
    throttle(() => {
      el.classList.toggle(`--active`, refEl.getBoundingClientRect().top < 0)
    })
  )
})
