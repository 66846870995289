import { addEventListener } from "@oddcamp/js-utils/src/event"

import init from "../utils/init"

// (un)follow

init(() => {
  const getContainerEl = (id) =>
    document.querySelector(`.follow[data-id="${id}"]`)

  const toggleButtonsVisibility = (id, type) => {
    const containerEl = getContainerEl(id)

    containerEl
      .querySelector(`.js--follow-btn-follow`)
      .classList[type == `follow` ? `remove` : `add`](`is-hidden`)

    containerEl
      .querySelector(`.js--follow-btn-unfollow`)
      .classList[type == `follow` ? `add` : `remove`](`is-hidden`)
  }

  const toggleButtonsDisabled = (id, disabled) => {
    const containerEl = getContainerEl(id)
    containerEl
      .querySelectorAll(`button`)
      .forEach((b) => (b.disabled = disabled))
  }

  // follow form: before

  addEventListener(`.follow-form form`, `ajax:before`, (e, triggerEl) => {
    const containerEl = triggerEl.closest(`.follow-form`)
    const modalEl = containerEl.closest(`.modal`)
    modalEl.Modal.hide()
    toggleButtonsVisibility(containerEl.dataset.id, `unfollow`)
    toggleButtonsDisabled(containerEl.dataset.id, true)
  })

  // follow form: error

  addEventListener(`.follow-form form`, `ajax:error`, (e, triggerEl) => {
    const containerEl = triggerEl.closest(`.follow-form`)
    toggleButtonsVisibility(containerEl.dataset.id, `follow`)
  })

  // follow form: complete

  addEventListener(`.follow-form form`, `ajax:complete`, (e, triggerEl) => {
    const containerEl = triggerEl.closest(`.follow-form`)
    toggleButtonsDisabled(containerEl.dataset.id, false)
  })

  // unfollow button: before

  addEventListener(
    `.follow  .js--follow-btn-unfollow`,
    `ajax:before`,
    (e, unfollowEl) => {
      const containerEl = unfollowEl.closest(`.follow`)
      toggleButtonsVisibility(containerEl.dataset.id, `follow`)
      toggleButtonsDisabled(containerEl.dataset.id, true)
    }
  )

  // unfollow button: error

  addEventListener(
    `.follow  .js--follow-btn-unfollow`,
    `ajax:error`,
    (e, unfollowEl) => {
      const containerEl = unfollowEl.closest(`.follow`)
      toggleButtonsVisibility(containerEl.dataset.id, `unfollow`)
    }
  )

  // unfollow button: complete

  addEventListener(
    `.follow .js--follow-btn-unfollow`,
    `ajax:complete`,
    (e, unfollowEl) => {
      const containerEl = unfollowEl.closest(`.follow`)
      toggleButtonsDisabled(containerEl.dataset.id, false)
    }
  )
})

// display modal

init(() => {
  addEventListener(`.follow`, `follow:displayModal`, (e, containerEl) => {
    containerEl.querySelector(`.js--follow-btn-follow`).click()
  })
})
